import * as React from "react"
import Page from '../components/page/Page';
import '../styles/styles.scss';
import './index.scss';

const Index = () => {
  return (
    <Page className="sds-page--index">
      <div className="sds-hero">
        <div className="sds-hero--content">
          <h1 className="sds-margin-block-end--l2">Let's start the musical journey!</h1>
          <p className="sds-margin-block-end--base">
            Follow my <a href="https://instagram.com/skralanofficial" target="_blank" rel="noreferrer">@skralanofficial instagram account</a> and start creating some buzz. Much appreciated!
          </p>
        </div>
        <div className="sds-hero--image sds-position--relative">
          <img src="./images/home_cover_skralan_playing_guitar.png" alt={"Skralan playing guitar."} />
          <img src="./images/home_cover_skralan_playing_guitar.png" alt="Skralan playing guitar."
            className="sds-position--absolute"
            style={{
              filter: "blur(20px) brightness(2.5) contrast(2)",
              transform: "scale(1.3, 1.05)",
              zIndex: "-1"
            }} />
        </div>
      </div>
    </Page >
  )
}

export default Index

export const Head = () => <title>Skralan 🎸🎵</title>
